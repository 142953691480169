const DatalayerHelper = {
  /**
   * Send the conversion event on dataLayer.
   * @param integer newTenantBookingId
   * @param object  reservation
   */
  sendConversionEvent: (newTenantBookingId, reservation) => {
    const reservedRooms = Object.entries(reservation.reserve).map(
      (entry, index) => {
        return {
          id: entry[0],
          name: entry[1].description,
          price: entry[1].price,
          quantity: entry[1].units,
        };
      }
    );

    const conversionDataLayer = {
      event: 'purchase',
      name: reservation.name,
      surname: reservation.surname,
      email: reservation.email,
      country: reservation.country,
      arrival: reservation.arrival,
      departure: reservation.departure,
      eventDetails: {
        category: 'Ecommerce',
        action: 'Purchase',
      },
      ecommerce: {
        purchase: {
          actionField: {
            id: newTenantBookingId,
            revenue: reservation.total,
            tax: 0,
            shipping: 0,
            coupon: reservation.coupon_code,
            action: 'purchase',
          },
          products: reservedRooms,
        },
      },
    };

    if (window.dataLayer) {
      window.dataLayer.push(conversionDataLayer);
    }
  },
};

export default DatalayerHelper;
