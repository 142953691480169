import moment from 'moment';
import { http } from '../../helpers/http';
import * as PropertyService from '../../services/Property';
import { setProperty, setPropertyIsReady } from './actions';
import * as CountriesThunks from '../countries/thunks';
import * as AvailabilityThunks from '../availability/thunks';
import StringHelper from '../../helpers/string-helper';
import DateTimeHelper from '../../helpers/date-time-helper';
import UrlHelper from '../../helpers/url-helper';
import * as TravelActions from '../travel/actions';
import NumberHelper from '../../helpers/number-helper';
import ObjectHelper from '../../helpers/object-helper';
import * as ReservationActions from '../reservation/actions';
import { setUnknownProperty } from '../application/actions';

export const loadProperty = (
  arrival,
  departure,
  couponCode,
  currency,
  adults,
  totalChildren,
  ageList,
  room,
  utmSource,
  askSuiteId,
  history,
  forceAvaiabilityUpdate = false,
  callback = false,
  errorCallback = false
) => {
  return async (dispatch, getState) => {
    const {
      application: {
        ready,
        property: { uid, subpropertyUid },
        i18n: { currentLanguage },
      },
      availability: { arrivalDate, departureDate, rooms: availabilityRooms },
      reservation: { percentageDiscount, valueDiscount },
    } = getState();

    if (!ready) {
      return;
    }

    let hasAvailability =
      DateTimeHelper.isUnformattedDate(arrival) &&
      DateTimeHelper.isUnformattedDate(departure);

    if (arrivalDate === arrival && departureDate === departure) {
      hasAvailability = false;
    }

    const onSuccess = () => {
      if (callback !== false) {
        callback();
      }
    };

    const onError = () => {
      if (errorCallback !== false) {
        errorCallback();
      }
    };

    const [success, data] = await http.request(
      PropertyService.SERVICE_PROPERTY,
      {
        l: currentLanguage.toString().toLowerCase(),
        h: uid,
        s: subpropertyUid,
      }
    );

    if (success) {
      const { propertyInfo, hqbookingsInfo } = data;
      const {
        property_main_photo: mainPhoto,
        property_photos: commonPhotos,
        property_home_central_photos: centralPhotos,
      } = hqbookingsInfo;
      const {
        main: centralMainPhotos,
        secondary: secondaryMainPhotos,
      } = centralPhotos;
      const { config: hqbookingsConfig } = hqbookingsInfo;

      let baseWidePhotos = [];

      if (ObjectHelper.isObject(centralMainPhotos)) {
        baseWidePhotos.push({ ...centralMainPhotos, main_photo: 1 });
      }

      if (Array.isArray(secondaryMainPhotos)) {
        baseWidePhotos = [
          ...baseWidePhotos,
          ...secondaryMainPhotos.map(photo => {
            return { ...photo, main_photo: 0 };
          }),
        ];
      }

      const photos = commonPhotos.map(photo => {
        let label = '';

        if (
          photo.legend &&
          photo.legend.name &&
          StringHelper.isNotEmpty(photo.legend.name)
        ) {
          label = photo.legend.name;
        }

        return {
          url: photo.storage_path,
          thumbnailUrl: photo.storage_path,
          label,
          main: !!+photo.main_photo,
        };
      });

      const widePhotos = baseWidePhotos.map(photo => {
        let label = '';

        if (
          photo.legend &&
          photo.legend.name &&
          StringHelper.isNotEmpty(photo.legend.name)
        ) {
          label = photo.legend.name;
        }

        return {
          url: photo.storage_path,
          thumbnailUrl: photo.storage_path,
          label,
          main: !!+photo.main_photo,
        };
      });

      let mainPhotoUrl = '';
      let mainPhotoThumbnailUrl = '';

      if (ObjectHelper.isObject(mainPhoto)) {
        mainPhotoUrl = ObjectHelper.pod(mainPhoto, 'storage_path', '');
        mainPhotoThumbnailUrl = ObjectHelper.pod(
          mainPhoto,
          'thumbnail_storage_path',
          ''
        );
      }

      const {
        config: { logo: logoUrl },
      } = hqbookingsInfo;

      const property = {
        ready: (hasAvailability) ? false :true,
        name: ObjectHelper.pod(propertyInfo, 'property_name', ''),
        uid: ObjectHelper.pod(propertyInfo, 'property_code', ''),
        gtm: ObjectHelper.pod(propertyInfo, 'google_tag', ''),
        logoUrl,
        email: ObjectHelper.pod(propertyInfo, 'contact_email', ''),
        phoneNumber: ObjectHelper.pod(propertyInfo, 'phone', ''),
        whatsapp: ObjectHelper.pod(propertyInfo, 'whatsapp', ''),
        address: ObjectHelper.pod(propertyInfo, 'address', ''),
        city: ObjectHelper.pod(propertyInfo, 'city', ''),
        state: ObjectHelper.pod(propertyInfo, 'state', ''),
        country: ObjectHelper.pod(propertyInfo, 'country_name', ''),
        countryCode: ObjectHelper.pod(propertyInfo, 'country_code', ''),
        countryCurrencyCode: ObjectHelper.pod(
          propertyInfo,
          'country_currency_code',
          ''
        ),
        countryCurrencyName: ObjectHelper.pod(
          propertyInfo,
          'country_currency_name',
          ''
        ),
        countryCurrencySymbol: ObjectHelper.pod(
          propertyInfo,
          'country_currency_symbol',
          ''
        ),
        latitude: ObjectHelper.pod(propertyInfo, 'latitude', ''),
        longitude: ObjectHelper.pod(propertyInfo, 'longitude', ''),
        aboutInfoText: ObjectHelper.pod(propertyInfo, 'about', ''),
        aboutCallInfoText: ObjectHelper.pod(propertyInfo, 'about_call', ''),
        termsInfoText: ObjectHelper.pod(propertyInfo, 'terms', ''),
        checkinDetailsInfoText: ObjectHelper.pod(
          propertyInfo,
          'checkin_details',
          ''
        ),
        cancelationPolicyInfoText: ObjectHelper.pod(
          propertyInfo,
          'cancelation_policy',
          ''
        ),
        depositBankTransferInfoText: ObjectHelper.pod(
          propertyInfo,
          'deposit_bank_transfer',
          ''
        ),
        photos,
        widePhotos,
        mainPhotoUrl,
        mainPhotoThumbnailUrl,
        config: {
          mainColor: hqbookingsConfig.main_color,
          releasePeriod: +ObjectHelper.pod(
            hqbookingsConfig,
            'release_period',
            0
          ),
          nightsLimit: +ObjectHelper.pod(hqbookingsConfig, 'nights_limit', 0),
          reservationMaxGuestsLimit: +ObjectHelper.pod(
            hqbookingsConfig,
            'reservation_max_guests_limit',
            0
          ),
          reservationMaxMonthsAhead: +ObjectHelper.pod(
            hqbookingsConfig,
            'reservation_max_months_ahead',
            0
          ),
          creditCardPaymentEnabled: !!+ObjectHelper.pod(
            hqbookingsConfig,
            'deposit_cc',
            0
          ),
          bankTransferPaymentEnabled: !!+ObjectHelper.pod(
            hqbookingsConfig,
            'deposit_bt',
            0
          ),
          depositPercentage: NumberHelper.toFloat(
            ObjectHelper.pod(hqbookingsConfig, 'deposit_percentage', 0)
          ),
          depositExpireDays: +ObjectHelper.pod(
            hqbookingsConfig,
            'deposit_expire_days',
            0
          ),
          additionalTax: NumberHelper.toFloat(hqbookingsConfig.additional_tax),
          acceptChildren: !!+ObjectHelper.pod(
            hqbookingsConfig,
            'accept_children',
            0
          ),
          minimunChildrenAge: +ObjectHelper.pod(
            hqbookingsConfig,
            'minimun_children_age',
            0
          ),
          paymentGatewayEnabled: ObjectHelper.pod(
            hqbookingsConfig,
            'payment_gateway_enabled',
            false
          ),
          paymentGatewayInstallmentsEnabled: ObjectHelper.pod(
            hqbookingsConfig,
            'payment_gateway_installments_enabled',
            false
          ),
          paymentGatewayInstallmentsLimit: ObjectHelper.pod(
            hqbookingsConfig,
            'payment_gateway_installments_limit',
            1
          ),
          paymentGatewayInstallments: ObjectHelper.pod(
            hqbookingsConfig,
            'payment_gateway_installments',
            []
          ),
          paymentGatewayPixEnabled: ObjectHelper.pod(
            hqbookingsConfig,
            'payment_gateway_pix_enabled',
            false
          ),
        },
        amenities: [...hqbookingsInfo.amenities],
      };

      dispatch(setProperty(property));
      dispatch(CountriesThunks.loadCountries(currency));

      if (hasAvailability || forceAvaiabilityUpdate) {
        const auxArrival = moment(arrival);
        const auxDeparture = moment(departure);
        const nights = auxDeparture.diff(auxArrival, 'days');

        if (StringHelper.isNotEmpty(couponCode)) {
          dispatch(
            ReservationActions.setCoupon(
              couponCode,
              percentageDiscount,
              valueDiscount
            )
          );
        } else {
          dispatch(
            ReservationActions.setCoupon('', percentageDiscount, valueDiscount)
          );
        }

        dispatch(
          ReservationActions.setUpdateCheckoutSummary(availabilityRooms)
        );
        dispatch(ReservationActions.setCheckoutSummary(nights));
        dispatch(ReservationActions.setUtmSource(utmSource));
        dispatch(ReservationActions.setAskSuiteId(askSuiteId));
        dispatch(TravelActions.setArrivalDate(arrival));
        dispatch(TravelActions.setDepartureDate(departure));
        dispatch(TravelActions.setTotalNights(nights));
        dispatch(TravelActions.setTotalAdults(adults));
        dispatch(TravelActions.setTotalChildren(totalChildren));
        
        ageList && ageList.map((age,k) => dispatch(TravelActions.setChildAge(age, k)));

        dispatch(TravelActions.setPreSelectedRoom(room));

        dispatch(
          AvailabilityThunks.fetchAvailability(
            arrival,
            departure,
            couponCode,
            adults,
            totalChildren,
            ageList,
            () => {
              dispatch(setPropertyIsReady());

              history.push(
                UrlHelper.toCurrentInternalRoute(
                  arrival,
                  departure,
                  couponCode,
                  currency,
                  adults,
                  totalChildren,
                  room,
                  askSuiteId
                )
              );

              onSuccess();
            },
            () => {
              dispatch(setPropertyIsReady());
              dispatch(TravelActions.setAsEmpty());
              history.push(UrlHelper.to(''));
              onError();
            },
            forceAvaiabilityUpdate
          )
        );
      } else {
        if (StringHelper.isNotEmpty(couponCode)) {
          dispatch(ReservationActions.setCoupon(couponCode, 0.0, 0.0));
        }
        onSuccess();
      }
    } else {
      dispatch(setUnknownProperty());
      onError();
    }
  };
};
