import ObjectHelper from './object-helper';
import StringHelper from './string-helper';
import DateTimeHelper from './date-time-helper';
import { STEP_PRODUCTS } from './steps';

const UrlHelper = {
  propertyCode: null,
  subpropertyCode: null,
  currentStep: '',

  to: (path, qs = {}, hash = '', usesProperty = true) => {
    let queryString = '';
    let hashTag = '';
    let currentPath = '/';

    if (!ObjectHelper.isEmpty(qs)) {
      queryString = Object.keys(qs)
        .map(key => {
          return { key, value: qs[key] };
        })
        .map(prop => `${prop.key}=${prop.value}`)
        .join('&');

      queryString = `?${queryString}`;
    }


    if (StringHelper.isNotEmpty(hash)) {
      hashTag = `#${hash}`;
    }

    if (path !== '/') {
      currentPath = `/${path}`;
    }

    if (usesProperty) {
      let subpropertyPath = '';

      if (StringHelper.isNotEmpty(UrlHelper.subpropertyCode)) {
        subpropertyPath = `/${UrlHelper.subpropertyCode}`;
      }

      return `/${UrlHelper.propertyCode}${subpropertyPath}${currentPath}${queryString}${hashTag}`;
    }

    return `/${currentPath}${queryString}${hashTag}`;
  },

  toInternalRoute: (
    route,
    arrival = '',
    departure = '',
    coupon = '',
    currency = '',
    adults = '',
    children = '',
    room = '',
    _askSI = ''
  ) => {
    let dqs = {};

    if (
      DateTimeHelper.isUnformattedDate(arrival) &&
      DateTimeHelper.isUnformattedDate(departure)
    ) {
      dqs = { arrival, departure };
    }

    if (StringHelper.isNotEmpty(coupon)) {
      dqs = { ...dqs, coupon };
    }

    if (StringHelper.isNotEmpty(currency)) {
      dqs = { ...dqs, currency };
    }

    if (StringHelper.isNotEmpty(adults)) {
      dqs = { ...dqs, adults };
    }

    if (StringHelper.isNotEmpty(children)) {
      dqs = { ...dqs, children };
    }

    if (StringHelper.isNotEmpty(room)) {
      dqs = { ...dqs, room };
    }

    if (StringHelper.isNotEmpty(_askSI)) {
      dqs = { ...dqs, _askSI };
    }

    return UrlHelper.to(route, dqs);
  },

  toCurrentInternalRoute: (
    arrival = '',
    departure = '',
    coupon = '',
    currency = '',
    adults = '',
    children = '',
    room = '',
    _askSI = '',
  ) => {
    let route = 'rooms';
    if (UrlHelper.currentStep === STEP_PRODUCTS) {
      route = 'products';
    }
    return UrlHelper.toInternalRoute(
      route,
      arrival,
      departure,
      coupon,
      currency,
      adults,
      children,
      room,
      _askSI
    );
  },

  getQueryParam: param => {
    try {
      if (window.location && window.location?.search?.includes(param)) {
        return new URLSearchParams(window.location.search).get(param);
      }
    } catch (error) {
      return null;
    }
    return null;
  },
};

export default UrlHelper;
