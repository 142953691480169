import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import ChooseButtons from '../../../ChooseButtons';
import NumberHelper from '../../../../helpers/number-helper';
import PageHelper from '../../../../helpers/page-helper';

const RoomSaleBase = props => {
  const { t } = useTranslation();
  const {
    privateRoom,
    saleBase,
    totalAvailable,
    totalUsed,
    onSaleBaseChange,
    reservationRoom,
    canSale,
    isRoomAlreadySelectedInAnotherPlan,
    autoSelect,
  } = props;
  const { base, unitPrice, originalUnitPrice, childrenTotal } = saleBase;

  const guests = privateRoom ? +base : 1;
  const icon = guests > 1 ? faUsers : faUser;
  const hasDiscount = unitPrice !== originalUnitPrice;

  const maxSelections = totalAvailable - totalUsed;

  const salesBaseRoomsRequested = reservationRoom.guests.filter(
    r => r.salesBase === saleBase.base
  ).length;

  // Auto selection feature. Actually only used for googlehotel
  useEffect(() => {
    if (
      // eslint-disable-next-line radix
      parseInt(autoSelect) > 0 &&
      // eslint-disable-next-line radix
      parseInt(maxSelections) >= parseInt(autoSelect) &&
      // eslint-disable-next-line radix
      (!privateRoom || parseInt(saleBase.base) === parseInt(autoSelect)) &&
      localStorage.getItem('autoSelected') !== '1'
    ) {
      onSaleBaseChange(privateRoom ? 1 : autoSelect);
      localStorage.setItem('autoSelected', '1');
      setTimeout(() => PageHelper.scrollToId('checkoutSummaryContainer'), 1000);
    }
  }, [onSaleBaseChange, privateRoom, saleBase, autoSelect, maxSelections]);

  useEffect(() => {
    if (totalUsed > 0) {
      setTimeout(() => PageHelper.scrollToId('checkoutSummaryContainer'), 1000);
    }
  }, [totalUsed]);

  return (
    <div className="d-flex align-items-center flex-row justify-content-end" style={{ marginBottom: '10px' }}>

      <div className="flex-fill" style={{ textWrap: 'nowrap' }}>
        <label className="text-secondary">
          {childrenTotal === 0 ? guests : ''} 
          &nbsp;
          {childrenTotal === 0 ? <FontAwesomeIcon icon={icon} /> : ''} 
        </label>
      </div>

      <div className="d-flex flex-row align-items-center justify-content-end" style={{ textAlign: 'right' }}>

        <div className="d-flex flex-row justify-content-center align-items-center flex-wrap">
        {hasDiscount && (
            <span className="promo currency" style={{ textWrap: 'nowrap' }}>
              {NumberHelper.currencyFormatted(originalUnitPrice)}
            </span>
          )}
          <span className="value currency" style={{ textWrap: 'nowrap' }}>
            {NumberHelper.currencyFormatted(unitPrice)}
          </span>
        </div>

        <ChooseButtons
            type="unitsSelector"
            className="flex-fill"
            total={salesBaseRoomsRequested}
            max={maxSelections}
            maxReached={maxSelections <= 0}
            onTotalChange={onSaleBaseChange}
            sumup
            canSale={canSale}
            isRoomAlreadySelectedInAnotherPlan={isRoomAlreadySelectedInAnotherPlan}
          />

        {!isRoomAlreadySelectedInAnotherPlan && !canSale && (
          <button
            type="button"
            className="btn btn-raised btn-danger slim text-uppercase"
            style={{ cursor: 'default' }}
          >
            {t('labels.unavailable')}
          </button>
        )}
      </div>
    </div>
  );
};

RoomSaleBase.propTypes = {
  privateRoom: PropTypes.bool,
  saleBase: PropTypes.object.isRequired,
  totalAvailable: PropTypes.number.isRequired,
  totalUsed: PropTypes.number.isRequired,
  useBorder: PropTypes.bool,
  canSale: PropTypes.bool,
  onSaleBaseChange: PropTypes.func.isRequired,
  reservationRoom: PropTypes.object.isRequired,
};

export default RoomSaleBase;
