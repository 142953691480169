import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import CheckoutSummaryHeader from './Header';
import NumberHelper from '../../../../helpers/number-helper';
import UrlHelper from '../../../../helpers/url-helper';
import StringHelper from '../../../../helpers/string-helper';
import { STEP_PRODUCTS } from '../../../../helpers/steps';
import SummaryStatus from '../../../SummaryAlert';
import LangHelper from '../../../../helpers/lang-helper';

const CheckoutSummary = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const application = useSelector(state => state.application);
  const reservation = useSelector(state => state.reservation);

  const travel = useSelector(state => state.travel);
  const {
    totalAdults,
  } = travel;

  const { currentStep } = application;
  const { canSendReservation, onSendReservation } = props;
  const {
    totalAmount: baseTotalAmount,
    arrivalDate,
    departureDate,
    couponCode,
    couponIsAccumulative,
    summary: { rooms, products },
    percentageDiscount,
    valueDiscount,
    additionalTax,
    additionalTaxAmount,
    installmentRate,
    installmentRateAmount,
    depositPercentage,
    depositPercentageAmount,
    cleaningTax,
  } = reservation;

  const totalAmount =
    valueDiscount > 0 ? baseTotalAmount - valueDiscount : baseTotalAmount;
  const hasCheckoutSummary = totalAmount > 0;
  const hasRooms = rooms.length > 0;
  const hasProducts = products.length > 0;
  const hasAdditionalTax = +additionalTax > 0;
  const hasCleaningTax = +cleaningTax > 0;
  const valueDiscountDepositPercentageAmount =
    valueDiscount > 0 ? (valueDiscount * depositPercentage) / 100 : 0;
  const valueDiscountInstallmentRateAmount =
    valueDiscount > 0 ? (valueDiscount * installmentRate) / 100 : 0;

  const missingVacancy = (hasRooms && totalAdults > 0) ? totalAdults - rooms.reduce(function (tot, arr) {
    return tot + arr.realTotalGuests;
  }, 0) : 0;

  // region Events Handlers
  const onNextHandler = () => {
    if (canSendReservation) {
      onSendReservation();
    } else {
      let page = 'products';

      if (currentStep === STEP_PRODUCTS) {
        page = 'checkout';
      }

      history.push(
        UrlHelper.toInternalRoute(page, arrivalDate, departureDate, couponCode)
      );
    }
  };
  // endregion

  // region Helpers
  const roomTitle = (title, subtitle) => {
    if (subtitle !== '') {
      return `${title} (${subtitle})`;
    }

    return title;
  };
  // endregion
  
  if (!hasCheckoutSummary) {
    return (
      <>
        <div className="checkout-summary-container">
          <div className="card-body checkout-summary">
            <CheckoutSummaryHeader
              arrival={arrivalDate ?? ''}
              departure={departureDate ?? ''}
            />
            <div className="row pt-3 border-bottom">
              <div className="col">
                <p className="text-center textGreyLight">
                  {t('labels.noRoom')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  const discountTitle = StringHelper.isNotEmpty(couponCode)
    ? couponIsAccumulative
      ? t('coupon.accumulative_note')
      : t('coupon.title')
    : t('labels.discount');

  let discountBlock = '';
  if (percentageDiscount > 0) {
    discountBlock = (
      <div className="row mb-2">
        <div className="col-7 pr-0">
          <p className="text-left mb-1 small textGreyLight">{discountTitle}</p>
        </div>
        <div className="col-5 pl-0">
          <p className="text-right mb-1 textGreyLight">
            <span className="value small currency">
              (-) {percentageDiscount.toFixed(2)}%
            </span>
          </p>
        </div>
      </div>
    );
  }

  if (valueDiscount > 0) {
    discountBlock = (
      <div className="row mb-2">
        <div className="col-7 pr-0">
          <p className="text-left mb-1 small textGreyLight">{discountTitle}</p>
        </div>
        <div className="col-5 pl-0">
          <p className="text-right mb-1 textGreyLight">
            <span className="value currency small">
              {percentageDiscount > 0 && `(-)${percentageDiscount.toFixed(2)}%`}
              <br />
              (-){NumberHelper.currencyFormatted(valueDiscount)}
            </span>
          </p>
        </div>
      </div>
    );
  }

  let depositPercentageText = '';
  if (depositPercentage > 0) {
    depositPercentageText = (
      <div className="row py-2 mb-2 border-top">
        <div className="col d-flex justify-content-between">
          <span className="small textGreyLight mr-2">
            {t('titles.depositPercentage')}
            <br />
            {depositPercentage.toFixed(2)}%
          </span>
          <span className="value currency small real">
            {`${NumberHelper.currencyFormatted(
              depositPercentageAmount -
              installmentRateAmount -
              valueDiscountDepositPercentageAmount
            )}`}
          </span>
        </div>
      </div>
    );
  }

  let installmentText = '';

  if (installmentRate > 0) {
    installmentText = (
      <div className="row py-2 mb-2 border-top">
        <div className="col d-flex justify-content-between">
          <span className="small textGreyLight">
            {t('titles.installmentRate')}
            <br />
            {installmentRate.toFixed(2)}%
          </span>
          <span className="value currency small real">
            {`${NumberHelper.currencyFormatted(
              installmentRateAmount - valueDiscountInstallmentRateAmount
            )}`}
          </span>
        </div>
      </div>
    );
  }

  const mainCardClass = `card-body checkout-summary${hasRooms && ' highlighted'}`;

  return (
    <div className="checkout-summary-container" id="checkoutSummaryContainer">
      <div className={mainCardClass}>
        <CheckoutSummaryHeader
          arrival={arrivalDate}
          departure={departureDate}
        />

        {hasRooms && (
          <div className="row py-3">
            <div className="col">
              <div className="row mb-1">
                <div className="col-12 pr-0">
                  <p className="text-left mb-1 text-uppercase">
                    <strong>{t('titles.rooms')}</strong>
                  </p>
                </div>
              </div>
              <div className="room-index mb-2">
                {rooms.map((room, index) => (
                  <div
                    key={`room-summary-${index}`}
                    className="room-index mb-2"
                  >
                    <div className="row">
                      <div className="col-7 pr-0">
                        <p className="text-left mb-1">
                          {roomTitle(room.name, room.ratePlanTitle)}
                        </p>
                      </div>
                      <div className="col-5 pl-0">
                        <p className="text-right mb-1">x{room.totalGuests}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-7 pr-0">
                        <p className="text-left mb-1 small textGreyLight">
                          {t(LangHelper.getBoardTypeLangKey(+room.ratePlanBoardType))}
                        </p>
                      </div>
                      <div className="col-5 pl-0">
                        <p className="text-right mb-1 textGreyLight">
                          <span className="value currency">
                            {NumberHelper.currencyFormatted(room.subtotalPrice)}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {discountBlock}

        {hasCleaningTax && (
          <div className="row py-2 mb-2 border-top">
            <div className="col d-flex justify-content-between">
              <span className="small textGreyLight">
                {t('labels.cleaningTax')}
              </span>
              <span className="value textGreyLight currency small real">
                {NumberHelper.currencyFormatted(cleaningTax)}
              </span>
            </div>
          </div>
        )}

        {hasAdditionalTax && (
          <div className="row py-3 mb-2 border-top">
            <div className="col d-flex justify-content-between">
              <span className="small textGreyLight">
                {t('labels.additionalTax')}
                <br />
                {additionalTax.toFixed(2)}%
              </span>
              <span className="value textGreyLight currency small real">
                {`${NumberHelper.currencyFormatted(additionalTaxAmount)}`}
              </span>
            </div>
          </div>
        )}

        {hasProducts && (
          <div className="row py-3 border-top">
            <div className="col">
              <div className="room-index mb-3">
                <div className="row mb-1">
                  <div className="col-12 pr-0">
                    <p className="text-left mb-1 text-uppercase">
                      <strong>{t('titles.productsAndServices')}</strong>
                    </p>
                  </div>
                </div>

                {products.map((product, index) => (
                  <div
                    key={`room-summary-${index}`}
                    className="room-index mb-2"
                  >
                    <div className="row">
                      <div className="col-7 pr-0">
                        <p className="text-left mb-1">{product.name}</p>
                      </div>
                      <div className="col-5 pl-0">
                        <p className="text-right mb-1">x{product.total}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 pl-0">
                        <p className="text-right mb-1 small textGreyLight">
                          <span className="value currency">
                            {NumberHelper.currencyFormatted(
                              product.totalAmount
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="row py-3 mb-2 border-top">
          <div className="col d-flex justify-content-between">
            <span className="small textGreyLight">{t('labels.total')}</span>
            <span className="value currency real">
              {NumberHelper.currencyFormatted(
                totalAmount - installmentRateAmount
              )}
            </span>
          </div>
        </div>

        {depositPercentageText}

        {installmentText}

        <div className="row">
          <div className="col text-center">
            <button
              type="button"
              className="btn btn-lg btn-danger btn-block btn-raised btn-default text-uppercase"
              onClick={onNextHandler}
            >
              {canSendReservation
                ? t('labels.confirmReservation')
                : t('labels.reserveNow')}
            </button>
          </div>
          {((totalAdults > 0) && missingVacancy > 0) && <SummaryStatus adults={totalAdults} missingVacancy={missingVacancy} />}
        </div>
      </div>
    </div>
  );
};

CheckoutSummary.propTypes = {
  canSendReservation: PropTypes.bool,
  onSendReservation: PropTypes.func,
};

export default CheckoutSummary;
