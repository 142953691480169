import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import StringHelper from '../../../../helpers/string-helper';
import MapBox from '../../../MapBox';

// region Helpers

const findAddress = (street, city, state) => {
  if (
    StringHelper.isNotEmpty(street) &&
    StringHelper.isNotEmpty(city) &&
    StringHelper.isNotEmpty(state)
  ) {
    return `${street} - ${city}/${state}`;
  }

  return '';
};

const findPlace = (city, state) => {
  if (StringHelper.isNotEmpty(city) && StringHelper.isNotEmpty(state)) {
    return `${city}/${state}`;
  }

  return '';
};

const hasLocationPoints = (street, latitude, longitude) => {
  return (
    StringHelper.isNotEmpty(street) &&
    StringHelper.isNotEmpty(latitude) &&
    StringHelper.isNotEmpty(longitude)
  );
};

const hasAboutInfo = (about, street, latitude, longitude) => {
  return (
    StringHelper.isNotEmpty(about) ||
    hasLocationPoints(street, latitude, longitude)
  );
};

const hasContactInfo = (street, place, phoneNumber, email) => {
  return (
    StringHelper.isNotEmpty(street) ||
    StringHelper.isNotEmpty(place) ||
    StringHelper.isNotEmpty(phoneNumber) ||
    StringHelper.isNotEmpty(email)
  );
};

const findActiveIcon = (current, expected) => {
  return current === expected ? ' active' : '';
};

// endregion

const About = props => {
  const [currentTab, setCurrentTab] = useState('about');

  const { t } = useTranslation();
  const {
    about,
    latitude,
    longitude,
    street,
    city,
    state,
    phoneNumber,
    email,
    page,
  } = props;

  const address = findAddress(street, city, state);
  const place = findPlace(city, state);
  const hasAbout = hasAboutInfo(about, street, latitude, longitude);
  const hasContact = hasContactInfo(street, place, phoneNumber, email);

  useEffect(() => {
    setCurrentTab('contact');
  }, [page]);

  // region Events

  const onChangeTabHandler = tab => {
    setCurrentTab(tab);
  };

  // endregion

  if (!hasAbout && !hasContact) {
    return null;
  }

  return (
    <div className="about">
      <div className="card-body greydark px-0">
        <ul className="nav nav-tabs justify-content-center w-100">
          {hasAbout && (
            <li
              className="nav-item"
              onClick={() => {
                onChangeTabHandler('about');
              }}
            >
              <span
                className={`nav-link px-4 ${findActiveIcon(
                  currentTab,
                  'about'
                )}`}
              >
                {t('labels.about')}
              </span>
            </li>
          )}

          {hasContact && (
            <li
              className="nav-item"
              onClick={() => {
                onChangeTabHandler('contact');
              }}
            >
              <span
                className={`nav-link px-4 ${findActiveIcon(
                  currentTab,
                  'contact'
                )}`}
              >
                {t('labels.contact')}
              </span>
            </li>
          )}
        </ul>
        <div className="tab-content pb-2 py-3">
          {hasAbout && currentTab === 'about' && (
            <div className="tab-pane px-3 active">
              {StringHelper.isNotEmpty(about) && <p>{about}</p>}
              <div className="map-container mb-3 text-center">
                {hasLocationPoints(street, latitude, longitude) && (
                  <MapBox
                    address={address}
                    latitude={latitude}
                    longitude={longitude}
                  />
                )}
              </div>
            </div>
          )}

          {hasContact && currentTab === 'contact' && (
            <div className="tab-pane px-3 active">
              <div className="row">
                <div className="col">
                  {StringHelper.isNotEmpty(street) && <p> {street} </p>}
                  {StringHelper.isNotEmpty(place) && <p> {place} </p>}
                  {StringHelper.isNotEmpty(phoneNumber) && (
                    <p>
                      {t('labels.phone')}: {phoneNumber}
                    </p>
                  )}
                  {StringHelper.isNotEmpty(email) && (
                    <p>
                      {t('labels.email')}: {email}
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

About.propTypes = {
  about: PropTypes.string.isRequired,
  latitude: PropTypes.string.isRequired,
  longitude: PropTypes.string.isRequired,
  street: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default About;
